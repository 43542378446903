import './about.css';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CgArrowLongRight } from 'react-icons/cg';
import me from '../../assets/images/me.webp';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

const About = () => {

    //gsap animation

    const aboutTl = gsap.timeline();
    const headRef = useRef(null);

    useEffect(() => {
       
        const ctx = gsap.context(() => {
            aboutTl.from(".heading-background", {
                ease: "power1.in",
                duration: 1,
                x: "1000",
            })
            aboutTl.from(".about-heading", {
                opacity: 0,
                ease: "power1.inOut",
                duration: 1,
                x:"-10",
                y:"-10"
            });
            aboutTl.from(".heading-line", {
                opacity: 0,
                ease: "power1.in",
                duration: 1,
                x: "1000"
            });
            aboutTl.from(".about-info", {
                opacity: 0,
                ease: "power1",
                duration: 1,
                x: "-100"
            });
            aboutTl.from(".info-one", {
                opacity: 0,
                duration: .5,
                ease: "power1.in",
                x: "-1000"
            });
            aboutTl.from(".info-two", {
                opacity: 0,
                duration: .5,
                ease: "power1.in",
                x: "-1000"
            });
            aboutTl.from(".scroll-down", {
                opacity: 0,
                ease: "power1.in",
                duration: 1,
                y:"-100",
            })

        })  
            return () => ctx.revert()
        
    },[aboutTl]);

    const mainTl = gsap.timeline(({
        scrollTrigger: {
            trigger: headRef.current,
            start: "15% top",
            end: "bottom bottom",
            //markers: true,
            toggleActions: "play none none none",
        }
       
    }));

    useEffect(() => {
        const ctx = gsap.context(() => {
            mainTl.from(".info-text", {
                opacity: 0,
                ease: "power1.in",
                delay: 1,
                duration: 1,
            });
            mainTl.from(".me", {
                opacity: 0,
                ease:"power1.in",
                duration:.5,
                delay: .5
            });
            mainTl.from(".more-info-line", {
                opacity: 0,
                duration: .5,
                ease: "power1.in",
                x: "400",
                delay: .5
            });
            mainTl.from(".more-info p", {
                opacity: 0,
                duration: .5,
                ease: "power1.in",
                x: "0",
                delay: .5
            })
        })
            return () => ctx.revert()
    }, [mainTl]);

    return (
        <div id="about">
            <div className="heading-background" ref={headRef}></div>
                <h1 className="about-heading">About Me, My Self <span style={{color: "#78c4c8"}}>and</span> I<span style={{color: "#78c4c8"}}>.</span></h1>
            <div className="heading-line"></div>
            <div className="about-info">BOXING, <span style={{color: "#78c4c8"}}>WEBDEV,</span> READING,<br />
             DANCING <span style={{color: "#78c4c8"}}>HORRIBLY,</span>
             <br />LISTEN <span style={{color: "#78c4c8"}}>MUSIC</span> IN MY HOUSE, 
             <br /> BLA<span style={{color: "#78c4c8"}}>BLA</span>BLA.</div>
            <div className="info-container">
                <div className="info-one"></div>
                <div className="info-two"></div>
                <div className="scroll-down">Scroll
                    <div className="scroll-line"></div>
                </div>
                <div className="info-background">
                    <div className="info-text">
                       
                        <p>Belonging to the Millenial Generation, I have always found the advent of technology fascinating; but my real journey in the World of Technology started when there was the "Boom" of Social Blogs like My Space and Friendster for Asia. 
                        In addition, I always wanted to explore my creative side as well. So, I took a leap of faith and embarked on a new path combining the Analog and Digital worlds.</p>
                      
                        <p>
                        I attend Mohole School where I did Graphic Design for one year and Web - Developer the second year.  I am inspired by both the creative and analytical aspects of programming and aim to use the foundation I have built to further hone my skills.
                        </p>
                        <p>
                        Needless to say, the puzzles of web development are a healthy touch.
                        </p>
                        
                        <div className="timeline-button">
                        </div>
                    </div>
                    
                </div>
                <div className="me">
                    <img src={me} alt="John Marco Self Portrait" />
                </div>
                <div className="more-info">
                    <div className="more-info-line"></div>
                    <p>
                     Outside of coding, I love to travel and explore the wonders of our Beloved Mother Earth 🌏.
                    </p>
                </div>
           </div>
        </div>
    )
}

export default About;